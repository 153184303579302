<template>
  <div class="body container">
    <Breadcrumb />
    <template v-if="isRoleAdmin()">
      <CreateButton />
      <el-button type="success">{{ $t("pageTitle.Salary") }}</el-button>
      <el-button
        @click="
          () => {
            $router.push({ name: 'Bill' });
          }
        "
      >
        {{ $t("pageTitle.Bill") }}
      </el-button>
    </template>
    <h1 class="text-center mt-5 mb-3">
      {{ $t("pageTitle.Salary") }}
    </h1>
    <h2 v-if="isRoleAdmin()">{{ $t("salary.pendingPayment") }}</h2>
    <h2 v-else-if="isRoleTeacher()">
      {{ $t("salary.pendingReceivePayment") }}
    </h2>
    <el-table
      style="width: 100%"
      :data="$store.getters['salary/unpaids']"
      :row-class-name="() => 'tableRow'"
      @row-click="enterToSalaryDetail"
    >
      <el-table-column
        :label="$t('column.teacher')"
        prop="userName"
        width="150"
      />
      <el-table-column :label="$t('column.date')" width="120">
        <template slot-scope="scope">
          <el-tooltip
            v-if="isRoleAdmin() && isSalaryLate(scope.row.date)"
            popper-class="tooltipWrong"
            :content="$t('bill.paymentOvertime')"
            placement="top"
          >
            <span style="color: red; font-weight: bold;">
              {{ scope.row.date }}
            </span>
          </el-tooltip>
          <span v-else>{{ scope.row.date }}</span>
        </template>
      </el-table-column>
      <el-table-column width="160">
        <template slot-scope="scope">
          <div
            v-if="isRoleAdmin() && isSalaryLate(scope.row.date)"
            style="color: white; font-weight: bold; background: red; padding: 1px 2px"
          >
            <span>
              {{ $t('bill.paymentOvertime') }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.amount')" width="120">
        <template slot-scope="scope">
          <a v-if="scope.row.latestPayroll.id">
            {{
              addCurrencySymbol(
                scope.row.latestPayroll.total_price,
                scope.row.latestPayroll.price_currency
              )
            }}
          </a>
          <a v-else>
            {{ addCurrencySymbol(scope.row.amount, scope.row.currency) }}
          </a>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.billingProgress')">
        <template slot-scope="scope">
          <StepOfAdmin
            :isSalaryThisMonth="isSalaryThisMonth(scope.row.date)"
            v-if="isRoleAdmin()"
            :scope="scope"
          />
          <StepOfTeacher v-else-if="isRoleTeacher()" :scope="scope" />
        </template>
      </el-table-column>
    </el-table>
    <hr class="separate-line" />
    <h2 v-if="isRoleAdmin()">{{ $t("bill.paidTransaction") }}</h2>
    <h2 v-else-if="isRoleTeacher()">{{ $t("bill.receivedTransaction") }}</h2>
    <el-row type="flex" align="middle">
      <el-col :span="2">
        付款日期：
      </el-col>
      <el-col :span="22">
        <el-date-picker
          placeholder="Start"
          v-model="filterDateStart"
          type="date"
          value-format="yyyy-MM-dd"
        />
        ~
        <el-date-picker
          placeholder="End"
          v-model="filterDateEnd"
          type="date"
          value-format="yyyy-MM-dd"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="22">
        <el-input
          ref="filterInput"
          v-model="filterConditionText"
          placeholder="Please enter teacher/title/amount/paid date"
        />
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="setFilter">
          {{ $t("button.search") }}
        </el-button>
      </el-col>
    </el-row>
    <el-table :data="paids" width="100%">
      <el-table-column :label="$t('column.teacher')" prop="name" width="230" />
      <el-table-column :label="$t('column.title')">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'SalaryView',
              params: {
                teacherId: scope.row.teacher_user_id,
                salaryId: scope.row.id
              }
            }"
          >
            {{ scope.row.title }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.amount')" width="230">
        <template slot-scope="scope">
          {{
            addCurrencySymbol(
              Number(scope.row.total_price),
              scope.row.price_currency
            )
          }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.date')" width="230">
        <template slot-scope="scope">
          {{ instant.formatDate(scope.row.transaction_datetime) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="changePaidPage"
        :total="paidTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { instant } from "@ivy-way/material";
import "@/assets/scss/style.scss";
import Breadcrumb from "@/components/Breadcrumb";
import CreateButton from "./CreateButton";
import StepOfAdmin from "@/views/salary/StepOfAdmin";
import StepOfTeacher from "@/views/salary/StepOfTeacher";
import salaryApi from "@/apis/salary";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.Salary")} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    CreateButton,
    StepOfAdmin,
    StepOfTeacher
  },
  mixins: [moneyMixin, roleMixin],
  computed: {
    instant() {
      return instant;
    }
  },
  data() {
    return {
      filterConditionText: "",
      filterDateStart: null,
      filterDateEnd: null,
      pageSize: 15,
      currentPage: 1,
      unpaids: [],
      paids: [],
      paidTotal: 0
    };
  },
  watch: {
    async currentPage() {
      await this.fetchTeacherPaids();
    }
  },
  async created() {
    if (this.$route.query.page) {
      this.currentPage = Number(this.$route.query.page);
    }
    if (this.$route.query.searchText) {
      this.filterConditionText = this.$route.query.searchText;
    }
    if (this.$route.query.dateStart) {
      this.filterDateStart = this.$route.query.dateStart;
    }
    if (this.$route.query.dateEnd) {
      this.filterDateEnd = this.$route.query.dateEnd;
    }
    if (this.isRoleAdmin()) {
      await this.$store.dispatch("salary/fetchTeachersUnpaids");
    } else if (this.isRoleTeacher()) {
      await this.$store.dispatch("salary/fetchTeachersPayrolls");
    }

    await this.fetchTeacherPaids();
  },
  mounted() {
    this.$refs.filterInput.$el.addEventListener("keydown", e => {
      if (e.code === "Enter") this.setFilter();
    });
  },
  methods: {
    isSalaryThisMonth(lastLessonLog) {
      const lastLessonLogMonth = moment(lastLessonLog).format("MM");
      return lastLessonLogMonth === moment().format("MM");
    },
    enterToSalaryDetail(salary) {
      if (salary.latestPayroll.id) {
        this.$router.push({
          name: "SalaryView",
          params: {
            teacherId: salary.userId,
            salaryId: salary.latestPayroll.id
          }
        });
      } else {
        this.$router.push({
          name: "createSalaryWithTeacherId",
          params: {
            teacherId: salary.userId
          }
        });
      }
    },
    isSalaryLate(date) {
      const thisMonth = moment().format("YYYY-MM-01");
      const diffMonths = moment(thisMonth).diff(
        moment(date).format("YYYY-MM-01"),
        "months"
      );
      return diffMonths > 1;
    },
    changePaidPage(page) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page
        }
      });
    },
    setFilter() {
      this.$router.push({
        name: this.$route.name,
        query: {
          page: 1,
          searchText: this.filterConditionText,
          dateStart: this.filterDateStart,
          dateEnd: this.filterDateEnd
        }
      });
    },
    async fetchTeacherPaids() {
      const { data: paids, total } = await salaryApi.fetchTeacherPaids({
        search: this.filterConditionText,
        start_date: this.filterDateStart,
        end_date: this.filterDateEnd,
        pageSize: this.pageSize,
        page: this.currentPage
      });
      this.paids = paids;
      this.paidTotal = total;
    }
  }
};
</script>

<style scoped>
::v-deep .el-pagination {
  margin: 20px 0px;
}

::v-deep .el-step__description {
  padding-right: 0px;
}

.el-table__row:hover .el-steps ::v-deep .is-icon {
  background: rgb(245, 247, 250);
}

::v-deep .tableRow {
  cursor: pointer;
}

.error ::v-deep .is-icon {
  color: red;
}

.wrong {
  color: red;
}
</style>
