<template>
  <el-steps :active="transferStep(scope.row.latestPayroll.status)">
    <el-step icon="el-icon-s-order">
      <template slot="icon">
        <a v-if="scope.row.latestPayroll.id || isPaid">
          {{
            `進度報告（${scope.row.progress_reports_completed_count}/${scope.row.progress_reports_needed_count}）`
          }}
        </a>
        <span v-else-if="!isSalaryThisMonth" class="wrong">
          {{
            `進度報告（${scope.row.progress_reports_completed_count}/${scope.row.progress_reports_needed_count}）`
          }}
        </span>
        <span
          v-else-if="
            scope.row.progress_reports_completed_count ===
              scope.row.progress_reports_needed_count && isSalaryThisMonth
          "
          class="default"
        >
          {{
            `進度報告（${scope.row.progress_reports_completed_count}/${scope.row.progress_reports_needed_count}）`
          }}
        </span>
      </template>
    </el-step>
    <el-step icon="el-icon-s-order">
      <template slot="icon">
        <template v-if="scope.row.latestPayroll.id || isPaid">
          <span v-if="salayIsTemp" class="waiting">
            {{ $t("salary.process.editBill") }}
          </span>
          <el-tooltip
            v-else
            placement="top"
            :content="instant.formatDate(scope.row.latestPayroll.created_at)"
            popper-class="tooltipColor"
          >
            <a>{{ $t("salary.process.statementCreated") }}</a>
          </el-tooltip>
        </template>
        <span v-else-if="isViewMode" class="wrong">
          + {{ $t("salary.process.createBill") }}
        </span>
        <a v-else :href="`/salary/create/teacher/${scope.row.userId}`">
          <span :class="isSalaryThisMonth ? 'default' : 'wrong'">
            + {{ $t("salary.process.createBill") }}
          </span>
        </a>
      </template>
    </el-step>
    <el-step icon="el-icon-s-order">
      <template slot="icon">
        <el-tooltip
          placement="top"
          v-if="salaryIsSubmitedTransation || isPaid"
          :content="instant.formatDate(scope.row.latestPayroll.updated_at)"
          popper-class="tooltipColor"
        >
          <a>{{ $t("salary.process.statementConfirmed") }}</a>
        </el-tooltip>
        <el-tooltip
          placement="top"
          v-else-if="salaryIsSubmitedProblem"
          :content="
            instant.formatDate(scope.row.latestPayroll.latest_problem.created_at)
          "
          popper-class="tooltipWrong"
        >
          <span class="wrong">
            {{ $t("salary.process.statementIssue") }}
          </span>
        </el-tooltip>
        <span
          v-else
          :class="(scope.row.latestPayroll.id && !salayIsTemp) ? 'waiting' : ''"
        >
          {{ $t("salary.process.confirmPending") }}
        </span>
      </template>
    </el-step>
    <el-step icon="el-icon-s-order">
      <template slot="icon">
        <span v-if="isPaid">{{ $t("salary.process.statementPaid") }}</span>
        <span
          v-else
          :class="(salaryIsSubmitedTransation && !salaryIsSubmitedProblem) ? 'wrong' : ''"
        >
          {{ $t("salary.process.paymentPending") }}
        </span>
      </template>
    </el-step>
  </el-steps>
</template>

<script>
import { instant } from "@ivy-way/material";
import { EnumSalaryStatuses } from "@/enums";

export default {
  props: {
    scope: {
      type: Object,
      default: () => ({})
    },
    isPaid: {
      type: Boolean,
      default: false
    },
    isViewMode: {
      type: Boolean,
      default: false
    },
    isSalaryThisMonth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    instant() {
      return instant;
    },
    salaryIsCreated() {
      return this.scope.row.latestPayroll.status === EnumSalaryStatuses.created;
    },
    salaryIsSubmitedTransation() {
      return this.scope.row.latestPayroll.status === EnumSalaryStatuses.checked;
    },
    salaryIsSubmitedProblem() {
      return this.scope.row.latestPayroll.status === EnumSalaryStatuses.problem;
    },
    salaryHasSend() {
      return 0 < (this.scope.row.latestPayroll.payroll_emails || []).length;
    },
    salayIsTemp() {
      return !this.salaryHasSend && this.salaryIsCreated;
    }
  },
  methods: {
    transferStep(step) {
      if (this.salayIsTemp) return 1;
      if (this.isPaid) return 4;
      switch (step) {
        case EnumSalaryStatuses.created:
          return 2;
        case EnumSalaryStatuses.problem:
          return 2;
        case EnumSalaryStatuses.checked:
          return 3;
        default:
          return 1;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .is-icon {
  width: 110px;
}

::v-deep .el-step__main {
  text-align: center;
  width: 90px;
}

::v-deep .el-step__description {
  padding-right: 0px;
}

.wrong ::v-deep .is-icon {
  color: red;
}

.wrong {
  color: red;
}

.default {
  color: #c0c4cc;
}

.waiting {
  color: orange;
}
</style>
